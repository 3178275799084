import React from "react";
import * as styles from "./404.module.css";
import Layout from "../components/layout/layout";

export default function NotFound() {
  return (
    <Layout> 
      <h1>COLD CUSTARD!</h1>
      <p>Page not found</p>
    </Layout>
  );
}
